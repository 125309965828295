import { paramsMapper } from '../params-mapper';
/*
 * Adapter for Google GTag (AW + GA4)
 */
const channelParams = {
  price: 'price',
  id: 'id',
  currency: 'currency',
  name: 'name',
  category: 'category',
  brand: 'brand',
  variant: 'variant',
  list: 'list_name',
  quantity: 'quantity',
  step: 'checkout_step',
  option: 'checkout_option',
  position: 'list_position',
  coupon: 'coupon',
  affiliation: 'affiliation',
  revenue: 'value',
  tax: 'tax',
  sku: 'sku',
  shipping: 'shipping',
};

const getCurrencyFromContents = (params) =>
  params.contents && params.contents[0].currency;

const mapContents = ({ contents = [] }) =>
  contents.map(({ currency, ...product }) =>
    paramsMapper(product, channelParams),
  );

const getEventCategory = ({ origin }) =>
  origin ? `Enhanced Ecommerce - ${origin}` : 'Enhanced Ecommerce';

const getEventLabel = ({ name }) => (name ? name : '(not set)');

export const channelEvents = {
  PageView: ({ pagePath, pageTitle }) => [
    ['event', 'page_view', { page_path: pagePath, page_title: pageTitle }],
  ],
  AddProductImpression: (params) => [
    [
      'event',
      'view_item_list',
      {
        event_action: 'Product Impressions',
        event_category: getEventCategory(params),
        items: mapContents(params),
      },
    ],
  ],
  ClickProduct: ({ currency, ...params }) => [
    [
      'event',
      'select_content',
      {
        event_action: 'Product Click',
        event_category: getEventCategory(params),
        event_label: getEventLabel(params),
        content_type: 'product',
        items: [paramsMapper(params, channelParams)],
      },
    ],
  ],
  ViewContent: ({ currency, ...params }) => [
    [
      'event',
      'view_item',
      {
        event_action: 'View Content',
        event_category: getEventCategory(params),
        event_label: getEventLabel(params),
        items: [paramsMapper(params, channelParams)],
      },
    ],
  ],
  AddToCart: ({ currency, ...params }) => [
    [
      'event',
      'add_to_cart',
      {
        currency,
        event_action: 'Add to Cart',
        event_category: getEventCategory(params),
        event_label: getEventLabel(params),
        items: [paramsMapper(params, channelParams)],
      },
    ],
  ],
  RemoveFromCart: ({ currency, ...params }) => [
    [
      'event',
      'remove_from_cart',
      {
        currency,
        event_action: 'Remove from Cart',
        event_category: getEventCategory(params),
        event_label: getEventLabel(params),
        items: [paramsMapper(params, channelParams)],
      },
    ],
  ],
  InitiateCheckout: (params) => [
    [
      'event',
      'begin_checkout',
      {
        currency: getCurrencyFromContents(params),
        event_action: 'Initiate Checkout',
        event_category: getEventCategory(params),
        items: mapContents(params),
      },
    ],
  ],
  StartPayment: ({ option, ...params }) => [
    [
      'event',
      'checkout_progress',
      {
        ...paramsMapper({ step: 3, option }, channelParams),
        event_action: 'Start Payment',
        event_category: getEventCategory(params),
        currency: getCurrencyFromContents(params),
        items: mapContents(params),
      },
    ],
  ],
  AddPaymentInfo: ({ option, ...params }) => [
    [
      'event',
      'add_payment_info',
      {
        ...paramsMapper({ step: 4, option }, channelParams),
        event_action: 'Add Payment Info',
        event_category: getEventCategory(params),
      },
    ],
  ],
  CheckoutStep: ({ step = 5, option, ...params }) => [
    [
      'event',
      'checkout_progress',
      {
        ...paramsMapper({ step, option }, channelParams),
        event_action: `Checkout Step ${step}`,
        event_category: getEventCategory(params),
        currency: getCurrencyFromContents(params),
        items: mapContents(params),
      },
    ],
  ],
  Purchase: ({ revenue, id, currency, coupon, ...params }) => [
    [
      'event',
      'purchase',
      {
        ...paramsMapper({ revenue, id, currency, coupon }, channelParams),
        transaction_id: id,
        event_action: 'Purchase',
        event_category: getEventCategory(params),
        items: mapContents(params),
      },
    ],
  ],
  Lead: ({ category = 'Leads', action = 'Submitted', label = 'New Lead' }) => [
    [
      'event',
      'generate_lead',
      { event_category: category, event_action: action, event_label: label },
    ],
  ],
  CustomEvent: (params) => {
    const {
      event = 'customEvent',
      eventAction,
      eventCategory,
      eventLabel,
      ...restOfParams
    } = params;
    return [
      [
        'event',
        eventAction || event,
        {
          ...restOfParams,
          event_category: eventCategory,
          event_label: eventLabel,
        },
      ],
    ];
  },
};
